<template>
  <layout class="container">
    <div class="main">
      <!--更多寄件-->
      <Modal 
        v-model="isShowMore" 
        title="" 
        :z-index="110" 
        hideFooter
        modalStyle="width: 18.125rem;text-align: center;overflow: unset;"
        maskClickable>
        <div class="moresend">
          <div class="moresend-close">x</div>
          <div class="moresend-title">
            更多寄件，请跳转至微信小程序下单
          </div>
          <div class="moresend-img">
            <img class="moresend-img-xcx" src="../../../assets/images/xcx.png"/>
          </div>
          <p class="moresend-desc">
            直接点击下方按钮前往微信小程序<br />
            或截图保存
          </p>
          <button class="moresend-btn">前往小程序下单</button>
        </div>
      </Modal>
      <!--物品信息-->
      <Mpopup v-model="showCargo" title="物品信息" :show-close="true" height="72vh" @close="cancelCargo">
        <div style="overflow: scroll;flex: 1;">
          <div class="cargo">
            <div class="cargo-title">
              <span>物品类型（必填）</span><a @click="goUrl">什么不能寄?</a>
            </div>
            <div class="cargo-detail">
              <div v-for="(item, index) in cargoList" :key="index" :class="[chooseCargoIndex === index ? 'selected' : '']" @click="chooseCargo(item)">
                {{ item.name }}
              </div>
            </div>
            <div v-if="cargoList[chooseCargoIndex].name === '其他'" class="cargo-input">
              <input v-model="orderData.cargoInput" placeholder="请输入物品名称" placeholder-style="font-size: 1rem;color: #bebebe;" maxlength="12">
              <span>{{ orderData.cargoInput.length || 0 }}/12</span>
            </div>
            <div v-if="cargoList[chooseCargoIndex].data" class="cargo-desc">
              <div v-for="(item, index) in cargoList[chooseCargoIndex].data" :key="index" class="cargo-desc-detail" @click="selectCargoDesc(item, index)">
                <img v-if="orderData.cargoDesc.indexOf(item) > -1" src="../../../assets/icons/iconSelectedCheck.png"><img v-else src="../../../assets/icons/iconSelectCheck.png"><span>{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="weight">
            <div class="weight-title">
              <span>预估重量</span>
              <div class="weight-title-adjust">
                <span class="weight-title-adjust-sign" :class="[orderData.weight > 1 ? 'selected' : '']" @click="adjustWeight('sub')">-</span>
                <div class="weight-title-adjust-mid">
                  <input v-model="orderData.weight" type="number"><span class="weight-title-adjust-kg">公斤</span>
                </div>
                <span class="weight-title-adjust-sign" :class="[orderData.weight < 20 ? 'selected' : '']" @click="adjustWeight('add')">+</span>
              </div>
            </div>
            <div class="weight-info">
              实际重量会计算体积重并以快递员确定为准
            </div>
          </div>
          <div v-if="isShowBj" class="weight">
            <div class="weight-title">
              <span>保价</span>
              <div class="weight-title-adjust">
                <div class="bj-radio" :class="[orderData.chooseBjIndex == 0 ? 'choose' : '']" @click="chooseBj(0)">
                  <div class="bj-radio-round">
                  </div><span>不保价</span>
                </div>
                <div class="bj-radio" :class="[orderData.chooseBjIndex == 1 ? 'choose' : '']" @click="chooseBj(1)">
                  <div class="bj-radio-round">
                  </div><span>保价</span>
                </div>
              </div>
            </div>
            <div v-if="orderData.chooseBjIndex == 1" class="valins">
              <div class="valins-input">
                <input v-model="orderData.valinspay" type="number" 
                        placeholder="请输入保价金额（最高10000元）" placeholder-style="color: #bebebe"
                        :disabled="valinsProtocolMeta.show"
                >
                <span v-if="orderData.valinsFee">保费{{ showValinsFee }}元</span>
              </div>
              <div class="valins-agree">
                <div style="display: flex;">
                  <div class="valins-agree-checkbox" :class="[orderData.agreeValins ? 'checked' : '']" @click="toggleValinsAgree"></div>
                  <span @click="toggleValinsAgree">我已阅读并同意</span>
                  <span class="valins-agree-protocol" @click="valinsProtocolMeta.show = true">《快递100平台保价增值服务协议》</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="popbtn">
          <button @click="confirmCargo">
            完成
          </button>
        </div>
      </Mpopup>
      <!--End-->
      <div class="banner"></div>
      <div class="tab">
        <div class="tab-send">寄快递</div>
        <div class="tab-other" @click="goBig">
          寄大件
          <div class="tab-other-line"></div>
        </div>
        <div class="tab-other" @click="isShowMore = true">更多寄件</div>
      </div>
      <div class="info">
        <div class="info-main">
          <div class="info-main-icon">
            <div class="info-main-icon-send">寄</div>
          </div>
          <div class="info-main-addr" @click="goEdit('sendman')">
            <div class="top">
              <span class="top-area">{{sendman.xzqName.split(",").join('')}}</span>
              <span class="top-detail">{{sendman.address || ''}}</span>
            </div>
            <div v-if="!sendman.name" class="bottom" style="color: #ff4d4f;">完善寄件人信息</div>
            <div v-else class="bottom">{{ sendman.name }} {{ sendman.phone | encryptionPhone }}</div>
          </div>
          <div class="info-main-list" @click.stop="showContact('sendman')">
            <div>地址簿</div>
          </div>
        </div>
        <div class="info-main">
          <div class="info-main-icon">
            <div class="info-main-icon-rec">收</div>
          </div>
          <div class="info-main-addr" @click="goEdit('recman')">
            <div class="top">
              <span class="top-area">{{recman.xzqName.split(",").join('')}}</span>
              <span class="top-detail">{{recman.address || ''}}</span>
            </div>
            <div v-if="!recman.name" class="bottom" :style="{color: sendman.address ? '#ff4d4f' : '#b2bac1'}">完善收件人信息</div>
            <div v-else class="bottom">{{ recman.name }} {{ recman.phone | encryptionPhone }}</div>
          </div>
          <div class="info-main-list" @click.stop="showContact('recman')">
            <div>地址簿</div>
          </div>
        </div>
        <div class="info-cargo" @click="selectCargo">
          <span>物品信息：{{ orderData.cargo + '/' + orderData.weight + '公斤' + (orderData.chooseBjIndex == 1 ? '/保价' : '') }}</span>
        </div>
      </div>
      <div class="com">
        <div 
          class="content"
          :class="{'com-select': chooseIndex === index}"
          v-for="(item, index) in comList" :key="index"
          @click="chooseCom(index)">
          <div v-if="chooseIndex === index" class="content-gou">
            <img src="https://cdn.kuaidi100.com/images/rebuild/protocolSelect.png" />
          </div>
          <div class="content-name">
            <img class="content-name-img" src="https://cdn.kuaidi100.com/images/all/56/debangkuaidi.png"/>
            <span class="content-name-detail">{{item.name}}</span>
          </div>
          <div class="content-info">
            <div class="content-info-detail" style="margin-top: 6px;">
              <span class="content-info-detail-money">5</span>
              <span class="content-info-detail-unit">元</span>
            </div>
            <div class="content-info-detail">
              <span class="content-info-detail-origin">原价：</span>
              <span class="content-info-detail-orimoney">12元</span>
            </div>
            <div class="content-info-detail" style="margin-top: 4px;">
              <div class="content-info-detail-time">预计12/21送达</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gosend">
        <div class="gosend-btn">去寄件</div>
        <div class="gosend-tips">
          <div class="gosend-tips-content">
            <img src="https://cdn.kuaidi100.com/images/mexpress/icon-send1.png" />
            <span>丢件必赔</span>
          </div>
          <div class="gosend-tips-content">
            <img src="https://cdn.kuaidi100.com/images/mexpress/icon-send2.png" />
            <span>比价下单</span>
          </div>
          <div class="gosend-tips-content">
            <img src="https://cdn.kuaidi100.com/images/mexpress/icon-send3.png" />
            <span>上门取件</span>
          </div>
        </div>
      </div>
      <div class="coupon">
        <div class="coupon-title">福利专区</div>
        <div class="coupon-main">
          <div class="content">
            <div class="content-left">
              <div class="content-left-title">
                <span class="content-left-title-name">新人专属福利</span>
                <span class="content-left-title-price">￥3</span>
              </div>
              <div class="content-left-time">
                <span>有效期:2022/09/01</span>
              </div>
            </div>
            <div class="content-right">
              去使用
            </div>
          </div>
          <div class="content">
            <div class="content-left">
              <div class="content-left-title">
                <span class="content-left-title-name">新人专属福利</span>
                <span class="content-left-title-price">￥3</span>
              </div>
              <div class="content-left-time">
                <span>有效期:2022/09/01</span>
              </div>
            </div>
            <div class="content-right">
              去使用
            </div>
          </div>
        </div>
      </div>
      <div class="unpay">
        <div class="unpay-desc">
          <span class="unpay-desc-close">×</span>您有2个订单<span class="unpay-desc-blue">待支付</span>，请立即支付
        </div>
        <div class="unpay-btn">
          <button>立即支付</button>
        </div>
      </div>
    </div>
  </layout>
</template>
<style lang="scss" scoped>
  @import './newIndex.scss';
</style>
<script>
import util from "lib/util"
import Mpopup from "components/Mpopup/Mpopup"
import Modal from "components/Modal/Modal"
export default {
  name: "courier",
  components: {
    Mpopup,
    Modal
  },
  data(){
    return {
      comList: [
        { name: '极兔快递' }, { name: '极兔快递' }, { name: '极兔快递' }, { name: '极兔快递' }, { name: '极兔快递' }, { name: '极兔快递' }, { name: '极兔快递' }
      ],
      position: {},
      heavyCourier: {},
      islogin: false,
      sendman: {
        xzqName: '广东,深圳市'
      },
      recman: {
        xzqName: '广东,深圳市'
      },
      orderData: {
        cargo: '日用品',
        weight: 1,
        cargoInput: '',
        chooseBjIndex: 0,
        valinsFee: '',
        agreeValins: false,
        valinspay: '',
        cargoDesc: [],
      },
      showCargo: false,
      orderDataBackup: {},
      oldcargoDesc: [],
      cargoList: [ {name:'日用品', data: ['含液体', '含电池']}, 
        {name:'服饰'}, 
        {name:'文件', data: ['含法律公文']}, 
        {name:'食品', data: ['含液体']},
        {name:'证件'},
        {name:'数码家电', data: ['含电池']},
        {name:'其他', data: ['含液体', '含电池']},
      ],
      isShowBj: false,
      availableCom4BrandList: [],
      isShowMore: false,
      chooseIndex: 0
    }
  },
  computed: {
    chooseCargoIndex() {
      let index
      for (let i = 0; i < this.cargoList.length; i++) {
        if (this.cargoList[i].name === this.orderData.cargo) {
          index = i
        }
      }
      return index || 0
    },
  },
  mounted() {
    this.login(() => {
      this.islogin = true
    })
    this.getCurrAddress()
  },
  activated () {
    this.checkLoc() // 检查是否有定位
  },
  methods: {
    getCurrAddress() { // 根据ip获取省市
      const url = '/apicenter/openapi.do?method=getCurrAddress'
      const params = {
        handleFail: false
      }
      this.$http.post(url, params).then((res) => {
        let city = res.data.city || ''
        const province = res.data.province.replace('省', '')
        if (['北京市', '天津市', '上海市', '重庆市'].indexOf(city) > -1) {
          city = city.replace('市', '')
        }
        if (city && province) {
          this.sendman.xzqName = `${province},${city}`
        }
      })
    },
    chooseCom(index) {
      this.chooseIndex = index
    },
    chooseCargo (item) { // 点击物品类型
      this.orderData.cargoDesc = []
      this.orderData.cargo = item.name
    },
    adjustWeight (tag) { // 调整重量
      if (tag === 'add') {
        this.orderData.weight = this.orderData.weight > 19 ? this.orderData.weight : this.orderData.weight + 1
      } else {
        this.orderData.weight = this.orderData.weight < 2 ? this.orderData.weight : this.orderData.weight - 1
      }
    },
    selectCargoDesc (item, index) { // 物品类型 带液体等 打勾
      if (this.orderData.cargoDesc.indexOf(item) > -1) {
        this.orderData.cargoDesc.splice(this.orderData.cargoDesc.indexOf(item), 1)
      } else {
        this.orderData.cargoDesc.push(item)
      }
    },
    clone (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    goUrl () {
      this.$router.push({
        name: "cargoInfo"
      })
    },
    includes (arr1, arr2) {
      return arr2.every(val => arr1.includes(val));
    },
    confirmCargo () { // 物品信息 完成
      if (this.orderData.cargo == '其他' && !this.orderData.cargoInput) {
        this.$toast('请输入物品名称')
        return
      }
      if (isNaN(this.orderData.weight) || (this.orderData.weight < 1 || this.orderData.weight > 20)){
        this.$toast('物品重量：请输入1-20之间的整数')
        return
      } 
      if (this.isShowBj && this.orderData.chooseBjIndex == 1 ) { // 选了保价
        let valinspay = this.orderData.valinspay
        if (!valinspay || Number(valinspay)%100 != 0) {
          this.$toast('请输入100的整倍数')
          return
        } else if (Number(valinspay) > 10000) {
          this.$toast('仅支持10000元以下的物品保价')
          return
        } else if (!this.orderData.agreeValins) {
          this.$toast('请阅读并同意《快递100平台保价增值服务协议》')
          return
        }
      }
      if (this.orderData.chooseBjIndex == 0) {
        this.orderData.valinspay = ''
        this.orderData.valinsFee = 0
      }
      this.orderData.cargo = this.cargoList[this.chooseCargoIndex].name
      this.showCargo = false
      if (this.orderData.cargoDesc.length > 0 || this.orderData.cargo === '证件') { // 有特殊物品
        if (!this.includes(this.oldcargoDesc, this.orderData.cargoDesc) || this.orderData.cargo === '证件') {
          this.$toast('包含特殊物品，请重新选择快递公司')
          // this.availableCom4Brand('set')
        }
      } else { // 无特殊物品
        if (this.availableCom4BrandList.length > 0) {
          // this.availableCom4BrandNew()
        } else {
          // this.availableCom4Brand('popup')
        }
      }
      this.cargoComplete += 1
    },
    cancelCargo () { // 取消物品弹窗
      this.orderData = this.orderDataBackup
    },
    isEmptyObj(obj) {
      return JSON.stringify(obj) === '{}'
    },
    selectCargo () { // 打开物品弹窗
      if (this.isEmptyObj(this.sendman) || this.isEmptyObj(this.recman)) {
        return this.$toast('请先填写收寄件人地址')
      }
      this.showCargo = true
      this.orderDataBackup = this.clone(this.orderData)
      this.oldcargoDesc = this.clone(this.orderData.cargoDesc)
    },
    showContact(type) { // 地址列表
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      this.broadcast() // 接收地址填充的通知
      this.$router.push({
        name: "addressList",
        params: {
          source: "dispatch"
        },
        query: {
          from: "dispatch",
          tag: type,
          selectId: this[type].id || -1
        }
      })
    },
    selectContact (data, type) { // 选择地址
      if (!data) return
      if (/台湾|香港|澳门/.test(data.xzqName) && type == 'send') {
        this.$confirm({
          content: "抱歉，目前暂不支持港澳台和国际件。服务正在筹备中，敬请期待！",
          type: 'alert',
          confirmText: "我知道了"
        })
        return
      }
      if(data.address.length < 4) {
        return this.$toast("详细地址不能少于4个字")
      }
      this[type] = data
    },
    goEdit (type) { // 地址信息栏
      if (!this.islogin) {
        return this.$router.push({
          name: "login"
        })
      }
      this.broadcast()
      this.$router.push({
        name: "addressDetail",
        params: {
          detail: Object.assign({}, this[type]),
          source: "dispatch",
          tag: type
        }
      })
    },
    broadcast() {
      this.$broadcastChannel.$on("fillContact", (addr, type) => {
        this.selectContact(addr, type)
        this.$broadcastChannel.$off("fillContact")
      })
    },
    loginCallback () { // 登录回调
      this.islogin = true
    },
    goMarket: function(market, isHeavy) { //跳转到寄件页
      let query = {
        lat: this.position.lat,
        lng: this.position.lng,
        orderSource: this.orderSource
      }, pathName
      if (market.status != 'OPEN') {
        this.$toast("当前所在城市暂未开通该服务，敬请期待")
        return
      }
      pathName = 'normal'
      query.sign = market.sign || ""
      util.query("couponId") && (query.couponId = util.query("couponId"))
      util.query("prizeCode") && (query.prizeCode = util.query("prizeCode"))
      this.stampid && (query.stampid = this.stampid)
      this.siid && (query.siid = this.siid)
      this.linkSource && (query.linkSource = this.linkSource)
      this.optor && (query.optor = this.optor)
      isHeavy && (query.heavy = 1)
      this.$router.push({
        name: pathName,
        query: query
      })
    },
    indexInfo (tag) { //获取超市
      this.loading = this.$loading('正在加载')
      this.$http.get('/apicenter/kdmkt.do?method=queryMyMkt', {
        data: {
          latitude: this.position.lat,
          longitude: this.position.lng,
          addressinfo: '',
          orderSource: 'orderindex',
          sortval: "all",
          apiversion: 14
        },
        handleFail: false,
        timeout: 5000
      }).then(res => {
        this.heavyCourier = res.sentBigMkt || {};
        if (tag === 'goBig' && this.heavyCourier.mktName) { // 有寄大件
          this.goMarket(this.heavyCourier, true)
        }
      }).catch(() => {
        
      }).finally(()=> {
        this.loading.hide()
      })
    },
    goBig() { // 去寄大件
      if (this.position.lat && this.heavyCourier.mktName) { // 有定位
        this.goMarket(this.heavyCourier, true)
      } else {
        this.loading = this.$loading('正在加载')
        util.locate({
          success: (position, data) => {
            this.loading.hide()
            this.position.lat = position.lat
            this.position.lng = position.lnt
            util.setLocalStorage_withTime('orderindex_location', {
              lng: position.lnt,
              lat: position.lat
            })
            this.indexInfo('goBig')
          },
          fail: this.locateFail,
        }, this, true)
      }
    },
    checkLoc() {
      let loc = util.getLocalStorage_withTime('orderindex_location')
      if (loc) { // 有缓存定位
        this.position = loc
        this.indexInfo()
      }
    },
    locateFail() {
      this.loading.hide()
      this.$toast('无定位权限，请选择地址')
      this.navigator.push({
        name: "location",
        params: {
          showPicker: true,
          locateInfo: '',
          keyword: '',
          source: "courier"
        }
      })
    },
  },
  watch: {
    
  },
  beforeDestroy: function () {
    
  }
}
</script>